import * as THREE from 'three'
import { Cylinder, Sphere } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { useSpring, useChain, useSpringRef, animated, easings} from '@react-spring/three'
import { MathUtils } from 'three'
import { config } from "./States/config";
import  Racket from './Racket'

export default function Player2(props)
{
    const head = useRef();
    const body = useRef(); 
    const racket = useRef();
    const hand = useRef()
    const trail = useRef();

    const [playerConfigs, setPlayerConfigs] = useState({
        side: 'player',
        bodyOffset: new THREE.Vector3(0,0,0),
        bodyRotation: 0.15,
        headOffset: 7.5, 
        headHeight: 1.6,
        handOffset: new THREE.Vector3(0.7,0,0)
    })

    const [move, setMove] = useState(0);
    const [targetPosition, setTargetPosition] = useState({x:-config.playerDistanceFromCenter-1, y:0, z:config.playerDistanceFromNet})

    const headSpringRef = useSpringRef()
    const headPos = useSpring({x: props.playerState.playerPosition === 'left'? -8.5: 5.5, 
        ref: headSpringRef,
        config: 
        { 
            mass: 5, 
            tension: 600, 
            friction: 70, 
            precision: 0.001, 
            easing: easings.easeInOutBounce 
        },
    })
    
    const startAngle = Math.PI/6; // Starting angle in radians
    const racketSpringRef = useSpringRef()
    const [{racketX, racketY, racketZ, racketRotationX, racketRotationY, racketRotationZ}, setSwingSpring] = useSpring(() => ({
        ref: racketSpringRef,
        racketX: 1 * Math.cos(startAngle), 
        racketY: 0, 
        racketZ: 1 * Math.sin(startAngle),
        racketRotationX: 0.2,
        racketRotationY: -1,
        racketRotationZ: -0.7, 
        config: {     
            mass: 5, 
            tension: 800, 
            friction: 50, 
            precision: 0.05, 
            easing: easings.easeInOutElastic
         },
         onRest: ()=>{props.updatePlayerStateProperties({isSwinging: false})}
        
    }));

    useChain([headSpringRef, racketSpringRef]);

    useEffect (()=>
    {
        setTargetPosition(props.playerState.playerPosition === 'left'? {x: -config.playerDistanceFromCenter-2, y:0, z: config.playerDistanceFromNet} :{x: config.playerDistanceFromCenter-2, y:0, z: config.playerDistanceFromNet})
        body.current.rotation.z = MathUtils.lerp(0, -2, 0.1)
        
    }, [props.playerState.playerPosition])

    useEffect (()=>
    {
        if (props.playerState.isSad)
            head.current.position.set(head.current.position.x -0.5, head.current.position.y - 0.3, head.current.position.z +1)

    }, [props.playerState.isSad])

    useEffect (()=>
    {
        if (props.playerState.isHappy)
            head.current.position.set(head.current.position.x, playerConfigs.headHeight + 1, head.current.position.z)
        else
            head.current.position.set(head.current.position.x, playerConfigs.headHeight, head.current.position.z)

    }, [props.playerState.isHappy])

    useEffect (()=>
    {
        props.updatePlayerStateProperties({isHappy: false})

    }, [])

    useFrame ((state, delta) =>
    {                     
            body.current.position.lerp(targetPosition, 10 * delta)

            if (body.current.position.distanceTo(targetPosition)<0.01)
            {    
                props.updatePlayerStateProperties({isMoving: false})            
                
                // setTrailLength(1);
            }
            if (props.playerState.isHumping)
            {
                body.current.position.y = props.playerState.isHappy? Math.abs(Math.sin(Math.PI * state.clock.elapsedTime)): (Math.sin(Math.PI * state.clock.elapsedTime)/6)
                hand.current.position.y = props.playerState.isHappy? 1.5 + Math.abs(Math.sin(Math.PI * state.clock.elapsedTime)): 1.5 + (Math.sin(Math.PI * state.clock.elapsedTime)/6)    
            }


        if (props.playerState.isSwinging) 
        {
            setSwingSpring.start({ 
                delay: 100,
                racketX: 0.5 * Math.cos(Math.PI),
                racketY: 1, 
                racketZ: -0.5 * Math.sin(Math.PI/2), 
                racketRotationX: 0.5,
                racketRotationY: 2.5,
                racketRotationZ: -1,
            });
            setTimeout(() => {
                if (props.playerState.isSwinging)
                    props.updatePlayerStateProperties({ isSwinging: false });
              }, 600); // Delay of 1 second (1000 milliseconds)        
            }
        else
        {
            setSwingSpring.start({ 
                racketX: 1 * Math.cos(startAngle), 
                racketY: 0, 
                racketZ: 1 * Math.sin(startAngle),
                racketRotationX: 0.3,
                racketRotationY: -1,
                racketRotationZ: -0.7, 
            });
        }       
    })

    return <>   
    
        {/* HAND */}
        <animated.group ref = {hand} position-x = {headPos.x} position-y = {1.5} position-z = {config.playerDistanceFromNet}>
                <animated.group ref = {racket} 
                    position-x = {racketX}
                    position-y = {racketY}
                    position-z = {racketZ}
                    rotation-x = {racketRotationX}
                    rotation-y = {racketRotationY}
                    rotation-z = {racketRotationZ}
                    >
                    <Sphere castShadow args = {[0.25, 32, 16]}>
                        <meshToonMaterial  color = "orange" />
                    </Sphere>
                    <Racket scale = {0.8}  
                        position-y={-0.4}
                    />
                </animated.group>    
 
        </animated.group>

        {/* HEAD */}
        <animated.group ref = {head} position-x = {headPos.x}>
            <Sphere castShadow args = {[0.55, 32, 16]} position = {[0, playerConfigs.headHeight, config.playerDistanceFromNet]}>
                <meshToonMaterial  color = "orange"/>
            </Sphere>
        </animated.group>

        {/* BODY */}
        <group ref = {body} position = {[-config.playerDistanceFromCenter-1, 0, config.playerDistanceFromNet]}>
            <Sphere args = {[0.7, 32, 16]} position = {[0, 1.7, 0]}>
                <meshToonMaterial  color = {0xe92d09}/>
                {/* //0xf3320c */}
            </Sphere>
            <Cylinder castShadow args = {[0.7, 0.8, 1.4, 16]} position = {[0,1,0]}>
                <meshToonMaterial  color = {0xe92d09}/>
            </Cylinder>
            <Cylinder castShadow args = {[0.7, 0.7, 0.6, 16]} position = {[0,0.2,0]}>
                <meshToonMaterial  color = "orange"/>
            </Cylinder>           
        </group>
    </>       
}