import * as THREE from 'three'
import React, { useCallback,useState, useRef, useEffect } from "react"
import { useSpring, animated, easings} from '@react-spring/three'
import { Trail, Html, useScroll } from "@react-three/drei";
import { CuboidCollider, RigidBody } from '@react-three/rapier'
import { useFrame } from "@react-three/fiber"
import { config } from "./States/config";
import Scoreboard from './Scoreboard'
import BouncingBall from "./BouncingBall";
import Player2 from './Player2'
import AI2 from './AI2'
import Environment from './Environment'


export default function Level2(props) {

    const scrollData = useScroll();
    const audioCache = {};
    let isAudioPlaying = false;
    const aiRef = useRef();
    const [hitOnTime, setHitOnTime] = useState(0);
    const [leftBtnColor, setLeftBtnColor] = useState(0xf2ef96)
    const [rightBtnColor, setRightBtnColor] = useState(0xf2ef96)

    const [gameState, setGameState] = useState({
        paused: true,
        serve: 'ai',
        turn: 'ai',
        ballPosition: "right",
        ballSide: 0, //0 for ai, 1 for player
        aiHitTime: 0,
        playerHitTime: 0,
        aiTimesHit: 0,
        playerTimesHit: 0,
        playerScore: 0,
        aiScore: 0,
        playerSets:0,
        aiSets:0,
        missChance: config.missChanceStartingLevel[0],
        smash: false
      });
    
    const updateGameStateProperties = (newProperties) => {
        setGameState((prevState) => ({
            ...prevState,
            ...newProperties,
        }));
    };

    const [playerState, setPlayerState] = useState({
        playerPosition: "left",
        isMoving: false,
        isSwinging: false,
        isHumping: true,
        isSad: false,
        isHappy: false,
        isServing: false,
        moveTo: "left",
    });

    const updatePlayerStateProperties = (newProperties) => {
        setPlayerState((prevState) => ({
            ...prevState,
            ...newProperties,
        }));
    };

    const [aiState, setAIState] = useState({
        aiPosition: "right",
        isMoving: false,
        isSwinging: false,
        isHumping: true,
        isSad: false,
        isHappy: false,
        isServing: false,
        moveTo: "left",
    });
  
    const updateAIStateProperties = (newProperties) => {
        setAIState((prevState) => ({
        ...prevState,
        ...newProperties,
        }));
    };

    const aiScrollPos = useSpring({
        x: scrollData.range(0,1) * 100 < 7? 0: -5.4,
        y: scrollData.range(0,1) * 100 < 7? 0: -1,
        z: scrollData.range(0,1) * 100 < 7? 0: 58,
        rotX: scrollData.range(0,1)* 100 < 7? 0: 0.2,
    config: 
    { 
        mass: 5, 
        tension: 600, 
        friction: 100, 
        precision: 0.1, 
        easing: easings.easeInOutElastic 
    },
    })

    const [bouncingBall, setBouncingBall] = useState(0) //0 Bouncing. 1. ThrowingUp 2. Serving. 3. InPlay
    const ballRef = useRef();

    const trailRef = useRef();
    const [trailLength, setTrailLength] = useState(1); // Initial length of the trail
    
    const playAudio = (path, volume, callback) => {
        if (isAudioPlaying) {
          return;
        }
      
        isAudioPlaying = true;
      
        // Check if the Audio object for this path already exists in the cache
        if (!audioCache[path]) {
          // If not, create a new Audio object and cache it
          audioCache[path] = new Audio(`./Sounds/${path}.mp3`);
        }
      
        // Retrieve the Audio object from the cache
        const audio = audioCache[path];
        
        // Reset the audio to start from the beginning
        audio.currentTime = 0;
        
        // Set the volume
        audio.volume = volume;
      
        // Add 'ended' event listener
        audio.addEventListener('ended', () => {
          isAudioPlaying = false;
          if (callback) {
            callback();
          }
        });
      
        // Play the audio
        audio.play();
      };

    const hit = ((who, serve)=>{
        resetForces()
        playhitSound();
        updateGameStateProperties({paused: false, turn: who === 'player'? 'ai': 'player'})
        const hitLeft = serve? 1:Math.random()<0.5
        const targetPoint =  new THREE.Vector3(hitLeft? -config.playerDistanceFromCenter: config.playerDistanceFromCenter, config.hitFromHeight, who==='ai'? config.playerDistanceFromNet:-config.playerDistanceFromNet )
        const hitFrom =      new THREE.Vector3(
            who==='ai'? 
            (aiState.aiPosition === 'left'? -config.playerDistanceFromCenter: config.playerDistanceFromCenter):
            (playerState.playerPosition === 'left'? -config.playerDistanceFromCenter: config.playerDistanceFromCenter),
            config.hitFromHeight, 
            who === 'ai'? -config.playerDistanceFromNet: config.playerDistanceFromNet)

        ballRef.current.setTranslation(hitFrom, true); 
        
        //Hit!
   
        let r = Math.round(Math.random() * (gameState.playerSets<4? config.smashChanceLevel[gameState.playerSets]: config.smashChanceLevel[4]))

        if (r === 0)
        {
            const bounceTarget = new THREE.Vector3().copy(targetPoint).add(new THREE.Vector3(0, 1, 0));
            const direction =    new THREE.Vector3().copy(bounceTarget).sub(hitFrom);
            direction.normalize();
            ballRef.current.applyImpulse(direction.multiplyScalar(8),true);    
            updateGameStateProperties({smash: false})
            setTrailLength(0.2)           
        }
        else if (r === 1)
        {
            const bounceTarget = new THREE.Vector3().copy(targetPoint).add(new THREE.Vector3(0, 0, 0));
            const direction =    new THREE.Vector3().copy(bounceTarget).sub(hitFrom);
            direction.normalize();
            ballRef.current.applyImpulse(direction.multiplyScalar(9),true); 
            updateGameStateProperties({smash: false})
            props.setCameraShake(0.3);
            setTrailLength(0.3)
        }
        else if (r === 2)
        {
            const bounceTarget = new THREE.Vector3().copy(targetPoint).add(new THREE.Vector3(0, -1, 0));
            const direction =    new THREE.Vector3().copy(bounceTarget).sub(hitFrom);
            direction.normalize();
            ballRef.current.applyImpulse(direction.multiplyScalar(9),true); 
            updateGameStateProperties({smash: false})
            props.setCameraShake(0.5);
            setTrailLength(0.3)
        }
        else if (r === 3)
        {
            const bounceTarget = new THREE.Vector3().copy(targetPoint).add(new THREE.Vector3(0, -2, 0));
            const direction =    new THREE.Vector3().copy(bounceTarget).sub(hitFrom);
            direction.normalize();
            ballRef.current.applyImpulse(direction.multiplyScalar(10),true); 
            updateGameStateProperties({smash: false})
            props.setCameraShake(0.8);
            setTrailLength(0.5)
        }
        else if (r === 4)
        {
            const bounceTarget = new THREE.Vector3().copy(targetPoint).add(new THREE.Vector3(0, -2, 0));
            const direction =    new THREE.Vector3().copy(bounceTarget).sub(hitFrom);
            direction.normalize();
            ballRef.current.applyImpulse(direction.multiplyScalar(11),true); 
            updateGameStateProperties({smash: false})
            props.setCameraShake(1.2);
            setTrailLength(0.8)
        }      
        else if (r === 5)
        {
            const bounceTarget = new THREE.Vector3().copy(targetPoint).add(new THREE.Vector3(0, -3, 0));
            const direction =    new THREE.Vector3().copy(bounceTarget).sub(hitFrom);
            direction.normalize();
            ballRef.current.applyImpulse(direction.multiplyScalar(13),true); 
            updateGameStateProperties({smash: true})
            props.setCameraShake(1.4);
            setTrailLength(1)
        }   
        else if (r === 6)
        {
            const bounceTarget = new THREE.Vector3().copy(targetPoint).add(new THREE.Vector3(0, -3, 0));
            const direction =    new THREE.Vector3().copy(bounceTarget).sub(hitFrom);
            direction.normalize();
            ballRef.current.applyImpulse(direction.multiplyScalar(15),true); 
            updateGameStateProperties({smash: true})
            props.setCameraShake(1.7);
            setTrailLength(1)
        }   
        else if (r === 7)
        {
            const bounceTarget = new THREE.Vector3().copy(targetPoint).add(new THREE.Vector3(0, -4, 0));
            const direction =    new THREE.Vector3().copy(bounceTarget).sub(hitFrom);
            direction.normalize();
            ballRef.current.applyImpulse(direction.multiplyScalar(18),true); 
            updateGameStateProperties({smash: true})
            props.setCameraShake(2);
            setTrailLength(1)
        } 


    })

    const playhitSound = ()=>{
        let r = Math.round(Math.random() * 7) + 1;
        playAudio(`Grunt${r}`, 0.1)
    }

    const playScoreSound = ()=>{
        let r = Math.round(Math.random() * 13) + 1;
        if (r>=10)
            playAudio(`Commentary10`, 0.1)
        else
            playAudio(`Commentary${r}`, 0.1)
    }

    const giveBallTo = ((who, forServe)=>
    {
        resetForces();
        let resetPosition;    
        if (who === 'player')
        {
            updatePlayerStateProperties({isServing: true})
            resetPosition = new THREE.Vector3(-config.playerDistanceFromCenter, config.hitFromHeight, config.playerDistanceFromNet)
        }
        else
        {
            updateAIStateProperties({isServing: true, aiPosition: 'right'})
            resetPosition = new THREE.Vector3(config.playerDistanceFromCenter, config.hitFromHeight + 4, -config.playerDistanceFromNet)
        }
        ballRef.current.setTranslation(resetPosition, true); 
        updateGameStateProperties({
            paused: forServe? true:false,
            ballPosition: 'right',
            serve: who==='ai'? 'ai':'player',
            aiTimesHit: 0, 
            playerTimesHit: 0, 
            missChance: gameState.playerSets<4? config.missChanceStartingLevel[gameState.playerSets]: config.missChanceStartingLevel[4]
        })
        if (forServe)
            setBouncingBall(0)
    })

    const serveMe = (()=>
    {
        //updateGameStateProperties({paused: false})
        setBouncingBall(1)
        playAudio("Bounce1", 0.01)

    })

    const resetForces = (()=>
    {
        ballRef.current.resetForces();
        ballRef.current.resetTorques();

        if (!ballRef.current.isSleeping())
            ballRef.current.sleep();
    })

    const hitLeft = useCallback(() => {
        if (!playerState.playerPosition.isMoving) {
            updatePlayerStateProperties({playerPosition: 'left', isMoving: true, isSwinging: true});
        }
    }, [playerState.playerPosition.isMoving, updatePlayerStateProperties]);

    const hitRight = useCallback(()=>
    {
        if (!playerState.playerPosition.isMoving)
        updatePlayerStateProperties({playerPosition: 'right', isMoving: true, isSwinging: true }) 
    }, [playerState.playerPosition.isMoving, updatePlayerStateProperties])

    useEffect(()=>
    {
        if (bouncingBall === 2) 
        {
            setBouncingBall(3)
            hit('ai', true)
            if (aiState.isServing)
                updateAIStateProperties({isSwinging: true, isServing: false})
        }
    }, [bouncingBall])

    useEffect(()=>
    {
        if (gameState.playerSets>0)
        updateAIStateProperties({isSad: true})
    }, [gameState.playerSets])

    useEffect(()=>
    {
        if (gameState.aiSets>0)
        updateAIStateProperties({isHappy: true})
    }, [gameState.aiSets])

    useEffect(() =>{
        if (gameState.ballSide === 'ai')
        {
            let r = Math.random() * 100
            let minHits = gameState.playerSets<4? config.minBeforeMissLevel[gameState.playerSets]:config.minBeforeMissLevel[4] 
            if (gameState.aiTimesHit < minHits || r > gameState.missChance)
            {   
                updateAIStateProperties({aiPosition: gameState.ballPosition});
                updateGameStateProperties({missChance: gameState.missChance + config.missChanceIncrement < (gameState.playerSets<4? config.missChanceCapLevel[gameState.playerSets]:config.missChanceCapLevel[4])? gameState.missChance + config.missChanceIncrement: gameState.missChance})  
            }
            else
            {
                updateAIStateProperties({aiPosition: gameState.ballPosition === 'left'? 'right': 'left'})
            }
        }

    }, [gameState.ballSide])

    useEffect(()=>{
        if (scrollData.range(0, 1) * 100 >1)
        updateAIStateProperties({aiPosition: 'right'})    
    },[scrollData])


    useEffect(() =>{    
        ballRef.current.setTranslation(new THREE.Vector3(0,0,-35), true); 
    }, [])


    useFrame((state, delta)=>{
        window.onkeydown = function(e) {
            if(e.keyCode == 32 && e.target == document.body) {
                e.preventDefault();
                if (gameState.paused)
                    serveMe();
                return false;
            }
            else if(e.keyCode == 37 && e.target == document.body) {
                e.preventDefault();
                hitLeft();
                return false;
            }
            else if(e.keyCode == 39 && e.target == document.body) {
                e.preventDefault();
                hitRight();
                return false;
            }
        };

        if (ballRef.current===null) return;

        if (ballRef.current.translation().x < 0)
            updateGameStateProperties({ballPosition: 'left'})
        else
            updateGameStateProperties({ballPosition: 'right'})

        if (ballRef.current.translation().z<0)
            updateGameStateProperties({ballSide: 'ai'})
        else
            updateGameStateProperties({ballSide: 'player'})     

        if (!gameState.paused)
        {
            if (ballRef.current.translation().z<-14 && !aiState.isSwinging && !aiState.isServing && aiState.aiPosition===gameState.ballPosition)
            {
                updateAIStateProperties({isSwinging: true})
                updateGameStateProperties({aiTimesHit: gameState.aiTimesHit + 1, turn: 'player'})
                hit('ai', false)   
            }
            if (ballRef.current.translation().z>10 && ballRef.current.translation().z<18)
            {
                // if (gameState.ballPosition === 'left')
                //     setLeftBtnColor('white')
                // else
                //     setRightBtnColor('white')

                if (gameState.turn === 'player' && playerState.isSwinging && playerState.playerPosition===gameState.ballPosition)
                {
                    setHitOnTime(1);
                }
            }
            else
            {
                setLeftBtnColor(leftBtnColor)
                setRightBtnColor(rightBtnColor)
            }

            if (hitOnTime && ballRef.current.translation().z>14)
            {
                updateGameStateProperties({playerTimesHit: gameState.playerTimesHit + 1, turn: 'ai'})
                hit('player', false)   
                setHitOnTime(0)
            }

            if (ballRef.current.translation().z<-40 && aiState.aiPosition != gameState.ballPosition)
            {
                giveBallTo('ai', true)
                playScoreSound();
                updateGameStateProperties({paused: true, playerScore: gameState.playerScore+1})
            }

            if (ballRef.current.translation().z>40)
            {
                giveBallTo('ai', true)
                playScoreSound();
                updateGameStateProperties({paused: true, aiScore: gameState.aiScore+1})
            }

        }  

    })

    return <>
        <BouncingBall bouncingBall = {bouncingBall}  ballColor = {0xccff00} setBouncingBall = {setBouncingBall}/>
        
        <Trail ref = {trailRef} width={10} color={0xccff00} length={trailLength} decay={2} local={false} attenuation={() => 1}>
            <RigidBody ref = {ballRef} name = {"ball"}  colliders="ball" restitution={1}>
                <mesh castShadow scale={0.4}>
                    <sphereGeometry />
                    <meshStandardMaterial visible= {!gameState.paused} color={0xccff00} />
                </mesh>
            </RigidBody>
        </Trail> 
    

        <CuboidCollider name = {"trailCollider"} 
            args = { [ 15, 5, 8 ] }
            position = { [0, 5, 0 ] }
            sensor
            onIntersectionEnter={(payload) => {setTrailLength(trailLength)}}
            onIntersectionExit={(payload) => {setTrailLength(0.1)}}
        />

        <CuboidCollider name = {"courtCollider"} 
            args = { [ 15, 1, 25 ] }
            position = { [0, -1, 0 ] }
            sensor
            onIntersectionEnter={(payload) => {(!bouncingBall<1)?payload.other.colliderObject.name === "ball"? playAudio("Bounce1", 0.05):null:null}}
        />

        <Scoreboard pScore = {gameState.playerScore} aScore = {gameState.aiScore} pSets = {gameState.playerSets} aSets = {gameState.aiSets} showScore = {true}  updateGameStateProperties = {updateGameStateProperties}/>
        
        <Html position={[0, -5, 0]} rotation-z={0.2} center>
            <div className="button-container">
                <button  id={"serveButton"} className={`pause-button ${(bouncingBall<1 && scrollData.range(0, 1) * 100<0.8)? 'unfade' : 'fade'}`} onClick={() => serveMe()}>READY?</button>
                    <div className="hit-button-container">
                        <button id={"leftButton"} className={`hit-button-style ${(!bouncingBall<1) ? 'unfade' : 'fade'}`} style={{margin: props.buttonsMargin}} onClick={() => {hitLeft()}}>Left</button>
                        <button id={"rightButton"} className={`hit-button-style ${(!bouncingBall<1) ? 'unfade' : 'fade'}`} style={{margin: props.buttonsMargin, }} onClick={() => {hitRight()}}>Right</button>
                    </div>
            </div>
        </Html> 

        <Player2  playerState = {playerState} updatePlayerStateProperties = {updatePlayerStateProperties} position = {{x:-config.playerDistanceFromCenter-1, y:0, z:config.playerDistanceFromNet}}/>

        <animated.group ref = {aiRef} position-x = {aiScrollPos.x} position-y = {aiScrollPos.y} position-z = {aiScrollPos.z} rotation-x={aiScrollPos.rotX}>
            <AI2 aiState = {aiState} updateAIStateProperties = {updateAIStateProperties} position = {{x:config.playerDistanceFromCenter-1, y:0, z:-config.playerDistanceFromNet}}/>
        </animated.group>

        
    </>
}