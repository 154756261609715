import { useScroll, Mask, useMask, useGLTF, Sphere, Image, Plane, Cylinder  } from '@react-three/drei'
import React, { useRef, useState } from "react";
import { useFrame } from '@react-three/fiber'
import { useSpring, useSpringRef, animated, easings} from '@react-spring/three'
import * as THREE from 'three'

export default function GamifyTheWorld(props)
{
    const stencil = useMask(1)
    const bars = useRef();
    const plumbob = useRef();
    const coffeebar =useRef();
    const productivtybar = useRef();
    const drinkHand = useRef();


    const { nodes, materials, animations } = useGLTF("/Models/Plumbob/the_sims_plumbob.glb");
    const scrollData = useScroll();

    const [progressColor, setProgressColor] = useState('green')
       
    const drinkSpringRef = useSpringRef()
    const [{rightHandX, rightHandY, rightHandZ, rightHandRotX, rightHandRotY, rightHandRotZ, scaleCup}, setDrinkSpring] = useSpring(() => ({
        ref: drinkSpringRef,

        config: {     
            mass: 5, 
            tension: 200, 
            friction: 10, 
            precision: 0.1, 
            easing: easings.easeInOutElastic
         },        
    }));

    useFrame ((state, delta) =>
    {
        drinkSpringRef.start({ 
            rightHandX:    (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldFrom + 2 ? -4.5: (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldTo - 1? -3.1: -4 ,
            rightHandY:    (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldFrom + 2 ? 0:  (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldTo - 1?  4: 1.3, 
            rightHandZ:    (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldFrom + 2 ? 1:  (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldTo - 1?  -1: -0.7, 
            rightHandRotX: (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldFrom + 2 ? 0:  (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldTo - 1?  2.2: 0,
            rightHandRotY: (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldFrom + 2 ? 0:  (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldTo - 1?  0: 0,
            rightHandRotZ: (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldFrom + 2 ? 0.2:  (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldTo - 1?  0.2: 0.2,
            scaleCup:      (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldFrom + 2 ? 0.7:  (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldTo - 1?  1: (scrollData.range(0,1) * 100) < props.whatsVisible.gamifytheworldTo - 5?0.8:0
        }); + 2 
        
        drinkHand.current.visible = bars.current.visible = (scrollData.range(0,1) * 100)>props.whatsVisible.gamifytheworldFrom && (scrollData.range(0,1) * 100)<props.whatsVisible.gamifytheworldTo
        plumbob.current.visible = (scrollData.range(0,1) * 100)>props.whatsVisible.gamifytheworldFrom && (scrollData.range(0,1) * 100)<props.whatsVisible.gamifytheworldTo

        coffeebar.current.position.x = Math.min(-35 + scrollData.range(0,1) * 110, -1)
        coffeebar.current.material.color = new THREE.Color(0xD64A4A).lerp(new THREE.Color(0x61DF90), scrollData.range(0.5,0.2))
      
        productivtybar.current.position.x = Math.min(-37 + scrollData.range(0,1) * 110, -0.5)
        productivtybar.current.material.color = new THREE.Color(0xD64A4A).lerp(new THREE.Color(0x61DF90), scrollData.range(0.6, 0.2))
        
        plumbob.current.rotation.z = state.clock.elapsedTime;    
    })

    return <>
    <group>
        <group ref = {bars} position={[-1, 7, 0]} rotation-x = {0.1} rotation-y = {-3} rotation-z = {-0.18} scale = {0.6}>
            <Mask id={1} position-x = {0.2} scale = {[5,6,1]}>
                <planeGeometry />
                {/* <meshBasicMaterial color = "orange"/> */}
            </Mask>
            <Image      
                url='./Textures/HealthBars.png' // Path to your PNG image
                // Position of the plane      
                scale={6}
                transparent = {true}
                flat = {true}       
                toneMapped = {false}
                opacity={0.8}
                
            />
            <Plane ref = {coffeebar} position={[0, -0.1 ,0.1]} scale-x= {4} scale-y={0.35}>
                <meshToonMaterial  {...stencil}/> 
            </Plane>

            <Plane ref = {productivtybar} position={[0, -1.34 ,0.11]} scale-x= {4} scale-y={0.35}>
                <meshToonMaterial color= {progressColor} {...stencil}/> 
            </Plane>
        </group>

        <group ref = {plumbob} position={[-0.5, 5, 0]} rotation={[-1.5, -0.17, 0]} scale={0.4}>
            <mesh
                name="Plumbob_Material001_0"
                geometry={nodes.Plumbob_Material001_0.geometry}
                material={materials["Material.001"]}
                material-flat={true}
            />
        </group>

        <group position-x = {3.2} position-y = {0} position-z = {0}>
            <animated.group ref = {drinkHand} scale = {scaleCup} position-x = {rightHandX} position-y = {rightHandY} position-z = {rightHandZ} rotation-x = {rightHandRotX} rotation-y = {rightHandRotY} rotation-z = {rightHandRotZ}>
                <Sphere args = {[0.25, 32, 16]}>
                    <meshStandardMaterial color = "orange" />
                </Sphere>
                <Cylinder args = {[0.35, 0.25, 0.7, 12]} position-x = {0.2} >
                    <meshStandardMaterial color = "red" />
                    <Cylinder args = {[0.32, 0.38, 0.1, 12]} position-y={0.4} >
                        <meshStandardMaterial color = "ivory" />
                    </Cylinder>
                </Cylinder>
            </animated.group> 
        </group>

    </group>
    </>
}

useGLTF.preload("/Models/Plumbob/the_sims_plumbob.glb");