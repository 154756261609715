import * as THREE from 'three'
import React, { useRef, useEffect } from "react";
import { useGLTF, useScroll } from "@react-three/drei";
import { useSpring, useSpringRef, animated, easings} from '@react-spring/three'
import { useFrame } from "@react-three/fiber";
import { MeshPhysicalMaterial } from 'three';

export default function Robot(props) {
  const holoRef = useRef();

  const scrollData = useScroll()
  const { nodes, materials } = useGLTF("/Models/Robot/robot.glb");

  const redLines = new THREE.ShaderMaterial({
    uniforms: {
      time: { value: 10.0 },
      resolution: { value: new THREE.Vector2() },
      opacity: { value: 1 }
    },
    transparent: true,
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
    uniform float time;
    uniform vec2 resolution;
    uniform float opacity;
    varying vec2 vUv;
    
    void main() {
      vec3 color = vec3(0.3, 0.4, 0.9); // green color
      float scanline = sin((vUv.y + 3.0 * time) * 3.0); // 300 can be adjusted for scanline density
      
      // Only show scanlines
      if (scanline > 0.5 || scanline < -0.5) {
        color *= 0.2;
      }
      
      gl_FragColor = vec4(color, opacity);
    }
  `
  });
  
  const hologramMaterial = new THREE.ShaderMaterial({
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthTest: true,
    uniforms: {
      time: { value: 1.0 },
      opacity: { value: 0.5 },
      scroller: { value: 0.1},
      color: { value: new THREE.Color(0x9c42e1)},
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform float time;
      uniform float opacity;
      uniform float scroller;
      varying vec2 vUv;
  
      void main() {
        vec3 color = vec3(0.2);
        float scanline = sin(vUv.x * 600.0 - time * 600.0);
        float glitch = scroller * 40.0;
  
        if(scanline > 0.90) {
          color = vec3(0.8, 0.8, 0.8); // Green color
        }
  
        // Applying glitch effect
        color.g += glitch;
  
        gl_FragColor = vec4(color, opacity);
      }
    `,
  });

  const hologramMaterial2 = new MeshPhysicalMaterial({transparent: true, opacity:0.3, toneMapped:false, emissive:"#EA00D9", emissiveIntensity:15})
  
  const robotHandSpring = useSpringRef()
  const [{robotHandX, robotHandY, robotHandZ, robotHandRotX, robotHandRotY, robotHandRotZ, robotScale, robotLight}, setRobotHandSpring] = useSpring(() => ({
    ref: robotHandSpring,
    config: {     
      mass: 5, 
      tension: 300, 
      friction: 100, 
      precision: 0.1, 
      easing: easings.easeInBack
    },
  }));

  const fresMat = new THREE.ShaderMaterial({
    uniforms: {
      color: { value: new THREE.Color('cyan') },
      opacity: { value: 0.3 },
      fresnelBias: { value: 0.9 },
      fresnelScale: { value: 0.2 },
      fresnelPower: { value: 3.0},
      time: { value: 1.0 }, // For animating the scanlines
    },
    vertexShader: `
      varying vec3 vReflection;
      varying vec3 vPosition;
      void main() {
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        vReflection = reflect(normalize(mvPosition.xyz), normalize(normal));
        vPosition = position;
        gl_Position = projectionMatrix * mvPosition;
      }
    `,
    fragmentShader: `
      uniform vec3 color;
      uniform float opacity;
      uniform float fresnelBias;
      uniform float fresnelScale;
      uniform float fresnelPower;
      uniform float time;
      varying vec3 vReflection;
      varying vec3 vPosition;
      void main() {
        float fresnel = fresnelBias + fresnelScale * pow(1.0 + dot(vReflection, vec3(1.0, 0.0, -1.0)), fresnelPower);
  
        // Scanline effect
        float scanline = sin(vPosition.z * 10.0 + time * 10.0);
        float lineStrength = 0.5; // Adjust the strength of the scanlines          
        scanline = clamp(scanline + lineStrength, 0.0, 3.0);
  
        gl_FragColor = vec4(color, opacity) * fresnel * scanline;
      }
    `,
    blending: THREE.AdditiveBlending,
    transparent: true,
    side: THREE.DoubleSide,
  });

  const randomizeOpacity = () => {
    // Generate a random opacity between 0.2 and 0.5
    const randomOpacity = Math.random() * (0.3 - 0.25) + 0.25;
    hologramMaterial2.opacity = randomOpacity;
  };

  const useMat = props.darkMode? hologramMaterial: hologramMaterial2;
  //const useMat = hologramMaterial;

  useFrame ((state, delta)=>{
    holoRef.current.visible = (scrollData.range(0,1) * 100)>props.whatsVisible.workstuffFrom && (scrollData.range(0,1) * 100)<props.whatsVisible.workstuffTo
    fresMat.uniforms.time.value += delta; // deltaTime is the time since the last frame
    hologramMaterial.uniforms.time.value += delta * 0.02;
    redLines.uniforms.time.value += delta * 1;

    setRobotHandSpring.start({
      robotHandRotX: (scrollData.range(0,1) * 100) < props.whatsVisible.hologramFrom? 10.5  : (scrollData.range(0,1) * 100)< props.whatsVisible.hologramTo? 8.5: 10.5,
      robotHandRotY: (scrollData.range(0,1) * 100) < props.whatsVisible.hologramFrom? 0     : (scrollData.range(0,1) * 100)< props.whatsVisible.hologramTo? 1: 0,
      robotHandRotZ: (scrollData.range(0,1) * 100) < props.whatsVisible.hologramFrom? 0     : (scrollData.range(0,1) * 100)< props.whatsVisible.hologramTo? 0.1: 0,
      robotScale:    (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 3? 0: (scrollData.range(0,1) * 100)< props.whatsVisible.workstuffTo -4? 5: 0,
    });
     hologramMaterial.uniforms.scroller.value = Math.min((scrollData.range(0,1) * 100 - props.whatsVisible.workstuffFrom - 5)/1000, 0.008) //0.01 * Math.abs(Math.sin(Math.PI/2 * state.clock.elapsedTime))
  })

  return (
    <group>
    <animated.group {...props} ref = {holoRef} scale={robotScale} position={[0,-1,3]} rotation={[0.5, 2.9, -0.2]}>
      <group>
        <group rotation={[-Math.PI / 2, 0, 0]}>
          <group 
            position={[0.014, 0.078, 0.956]}
            rotation={[Math.PI / 2, 0, 0]}
            scale={0.006}
          >
            <mesh
              geometry={nodes.Mesh039.geometry}
              material={useMat}
            />
            <mesh
              geometry={nodes.Mesh039_1.geometry}
              material={useMat}
            />
          </group>
          <mesh 
            geometry={nodes.Head.geometry}
            material={useMat}
            position={[0.006, -0.113, 1.865]}
            rotation={[1.568, 0, 3.142]}
            scale={-0.015}
          >
            {/* REDLINES */}
            <mesh
              geometry={nodes.Box001.geometry}
              material={redLines}
              position={[0, 3.763, -9.999]}
              rotation={[0, 0, -Math.PI]}
              scale={-0.683}
            />
          </mesh>
          <group
            position={[0.948, 0.077, 0.575]}
            rotation={[0.024, -1.038, -3.13]}
            scale={-0.012}
          >
            <mesh
              geometry={nodes.Mesh049.geometry}
              material={useMat}
            />
            <mesh
              geometry={nodes.Mesh049_1.geometry}
              material={useMat}
            />
          </group>
          <group
            position={[-0.173, 0.089, 1.659]}
            rotation={[1.562, 0.004, 1.571]}
            scale={0.01}
          >
            <mesh
              geometry={nodes.Mesh084.geometry}
              material={useMat}
            />
            <mesh
              geometry={nodes.Mesh084_1.geometry}
              material={useMat}
            />
          </group>
          <mesh
            geometry={nodes.R_Hand_002.geometry}
            material={useMat}
            position={[-0.216, 0.085, 1.314]}
            rotation={[1.562, 0.004, 0]}
            scale={0.01}
          />
          <animated.group
            position={[-0.641, 0.084, 1.209]}
            rotation-x={robotHandRotX}
            rotation-y={robotHandRotY}
            rotation-z={robotHandRotZ}
            //rotation={[robotHandRotX, robotHandRotY, robotHandRotZ]}
            scale={[0.015, 0.012, 0.015]}
          >
            <mesh
              geometry={nodes.Mesh082.geometry}
              material={useMat}
            />
            <mesh
              geometry={nodes.Mesh082_1.geometry}
              material={useMat}
            />
          </animated.group>
          <mesh
            geometry={nodes.R_Leg_001.geometry}
            material={useMat}
            position={[-0.277, 0.078, 1.152]}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            scale={0.006}
          />
          <mesh
            geometry={nodes.R_Leg_002.geometry}
            material={useMat}
            position={[-0.138, 0.078, 1.152]}
            rotation={[1.631, 0, Math.PI / 2]}
            scale={0.006}
          />
          <mesh
            geometry={nodes.R_Leg_003.geometry}
            material={useMat}
            position={[-0.322, 0.107, 0.665]}
            rotation={[1.62, 0, 0]}
            scale={0.01}
          />
          <mesh
            geometry={nodes.R_Leg_004001.geometry}
            material={useMat}
            position={[-0.226, 0.109, 0.643]}
            rotation={[1.631, 0, Math.PI / 2]}
            scale={0.008}
          />
          <mesh
            geometry={nodes.R_Leg_004002.geometry}
            material={useMat}
            position={[-0.226, 0.109, 0.643]}
            rotation={[1.631, 0, Math.PI / 2]}
            scale={0.008}
          />
          <mesh
            geometry={nodes.R_Leg_005.geometry}
            material={useMat}
            position={[-0.311, 0.137, 0.175]}
            rotation={[-1.514, 0, 0]}
            scale={-0.01}
          />
          <mesh
            geometry={nodes.R_Leg_006001.geometry}
            material={useMat}
            position={[-0.179, 0.148, 0.082]}
            rotation={[1.632, 0, 1.571]}
            scale={0.011}
          />
          <mesh
            geometry={nodes.R_Leg_006002.geometry}
            material={useMat}
            position={[-0.179, 0.148, 0.082]}
            rotation={[1.632, 0, 1.571]}
            scale={0.011}
          />
          <mesh
            geometry={nodes.R_Leg_007001.geometry}
            material={useMat}
            position={[0.189, 0.148, 0.082]}
            rotation={[-1.51, 0, 1.571]}
            scale={-0.011}
          />
          <mesh
            geometry={nodes.R_Leg_008.geometry}
            material={useMat}
            position={[0.321, 0.137, 0.175]}
            rotation={[1.631, 0, 0]}
            scale={0.01}
          />
          <mesh
            geometry={nodes.R_Leg_010001.geometry}
            material={useMat}
            position={[0.236, 0.109, 0.643]}
            rotation={[-1.51, 0, 1.571]}
            scale={-0.008}
          />
          <mesh
            geometry={nodes.R_Leg_010002.geometry}
            material={useMat}
            position={[0.236, 0.109, 0.643]}
            rotation={[-1.51, 0, 1.571]}
            scale={-0.008}
          />
          <mesh
            geometry={nodes.R_Leg_011.geometry}
            material={useMat}
            position={[0.325, 0.107, 0.665]}
            rotation={[-1.51, 0, 0]}
            scale={-0.01}
          />
          <mesh
            geometry={nodes.R_Leg_012.geometry}
            material={useMat}
            position={[0.148, 0.078, 1.152]}
            rotation={[-1.51, 0, 1.571]}
            scale={-0.006}
          />
          <mesh
            geometry={nodes.R_Leg_013.geometry}
            material={useMat}
            position={[0.287, 0.078, 1.152]}
            rotation={[-1.586, 0, Math.PI / 2]}
            scale={-0.006}
          />
          <group
            position={[0.328, 0.37, -0.088]}
            rotation={[-1.5, 0, 0]}
            scale={-0.01}
          >
            <mesh
              geometry={nodes.Mesh018.geometry}
              material={useMat}
            />
            <mesh
              geometry={nodes.Mesh018_1.geometry}
              material={useMat}
            />
          </group>
          <group
            position={[0.191, 0.089, 1.658]}
            rotation={[-Math.PI / 2, 0.003, 1.567]}
            scale={-0.01}
          >
            <mesh
              geometry={nodes.Mesh062.geometry}
              material={useMat}
            />
            <mesh
              geometry={nodes.Mesh062_1.geometry}
              material={useMat}
            />
          </group>
          <mesh
            geometry={nodes.Torso_seg_001.geometry}
            material={useMat}
            position={[0.014, 0.088, 1.644]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={0.01}
          />
          <mesh
            geometry={nodes.Torso_Shoulder_001.geometry}
            material={useMat}
            position={[-0.078, 0.084, 1.644]}
            rotation={[Math.PI / 2, 0, -Math.PI / 2]}
            scale={0.01}
          />
          <mesh
            geometry={nodes.Torso_Shoulder_003.geometry}
            material={useMat}
            position={[0.127, 0.084, 1.674]}
            rotation={[-2.094, 0, -Math.PI / 2]}
            scale={-0.014}
          />
        </group>
      </group>
    </animated.group>

    </group>
  );
}

useGLTF.preload("./Models/Robot/robot.glb");

