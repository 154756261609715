import React, { useEffect, useState } from 'react';

const ContactForm = (props) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
   setVisible(false)
  }, [])

  return visible? null:(

<div style={{ position: 'static'}}>
    <div id="container" style={{display: 'none'}}>
    <h1>&bull; Get in touch &bull;</h1>
    <div className="underline">
    </div>
    <form action="https://public.herotofu.com/v1/29202240-5451-11ee-99c9-b9cd803366c9" method="post" acceptCharset="UTF-8" id="contact_form">
        <div className="name">
            <label form="name"></label>
            <input type="text" placeholder="My name is" name="name" id="name_input" required/>
        </div>
        <div className="email">
            <label form="email"></label>
            <input type="email" placeholder="My e-mail is" name="email" id="email_input" required/>
        </div>
        <div className="message">
            <label form="message"></label>
            <textarea name="message" placeholder="I'd like to chat about" id="message_input" cols="30" rows="5" required></textarea>
        </div>
        <div className="submit">
            <input type="submit" value="Send Message" id="form_button"/>
        </div>
    </form>
    <div>
        <a href="https://www.linkedin.com/in/naumanshah1220/" target="_blank" rel="noopener noreferrer">
            <button className="linkedin-button">LinkedIn</button>
        </a>       
    </div>
    </div>
</div>
  );
};

export default ContactForm;