
export const config = {
    level: 2,
    hitFromHeight: 4,
    minBeforeMiss: 5,
    missChanceStarting: 50,
    missChanceIncrement: 5,
    missChanceCap: 75,
    playerDistanceFromCenter: 7,
    playerDistanceFromNet: 16,
    
    minBeforeMissLevel:      [3,4,5,6,7], 
    missChanceStartingLevel: [50,45,40,35,30],
    missChanceCapLevel:      [75,65,60,55,50],
    smashChanceLevel:        [4 , 5 , 6 , 7, 7]
  };