import { useScroll, Box,  Sphere} from '@react-three/drei'
import React, { useRef, useState } from "react";
import { useFrame } from '@react-three/fiber'
import { useSpring, useSpringRef, animated, easings} from '@react-spring/three'
import Robot from './Robot';

export default function WorkStuff(props)
{
    const scrollData = useScroll();
    const vrHand = useRef();
    const vrGlasses= useRef();

    const vrSpringRef = useSpringRef()
    const [{vrPosX, vrPosY, vrPosZ, vrRotX, vrRotY, vrRotZ, vrScale}, setVRSpring] = useSpring(() => ({
        ref: vrSpringRef,

        config: {     
            mass: 5, 
            tension: 200, 
            friction: 10, 
            precision: 0.1, 
            easing: easings.easeInOutElastic
         },        
    }));

    const vrHandSpring = useSpringRef()
    const [{vrHandX, vrHandY, vrHandZ, vrHandScale}, setVRHandSpring] = useSpring(() => ({
        ref: vrHandSpring,
        config: {     
            mass: 5, 
            tension: 200, 
            friction: 200, 
            precision: 0.1, 
            easing: easings.easeInBack
         },        
    }));

    useFrame ((state, delta) =>
    {
        vrHand.current.visible = ((scrollData.range(0,1) * 100) > props.whatsVisible.workstuffFrom &&  (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffTo)? true: false
        vrGlasses.current.visible = ((scrollData.range(0,1) * 100) > props.whatsVisible.workstuffFrom &&  (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffTo)? true: false
        
        vrSpringRef.start({ 
            vrPosX: (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom? -0.25 :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? -0.1: 0.8,
            vrPosY: (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom? 3.5   :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? 3.6: 1.7, 
            vrPosZ: (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom? 0.4   :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? -0.5: -1, 
            vrRotX: (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom? 1.6   :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? 0.3: 0,
            vrRotY: (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom? 0.02  :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? -0.3: 0,
            vrRotZ: (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom? 0.3   :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? 0.13: 0,
            vrScale:(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom? 1     :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? 1.1: (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 8? 0.5:0
        });

        vrHandSpring.start({ 
            vrHandX:    (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 7? 0.7 :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? 1: 0.8,
            vrHandY:    (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 7? 1   :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? 4: 1, 
            vrHandZ:    (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 7? 0.3 :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? -2: 0, 
            vrHandScale:(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 7? 1   :(scrollData.range(0,1) * 100) < props.whatsVisible.workstuffFrom + 14? 1: (scrollData.range(0,1) * 100) < props.whatsVisible.workstuffTo - 4? 0.8:0
        });
    })

    return<>
    <group>
        <animated.group ref = {vrGlasses} scale = {vrScale} position-x = {vrPosX} position-y = {vrPosY} position-z = {vrPosZ} rotation-x = {vrRotX} rotation-y = {vrRotY} rotation-z = {vrRotZ}>
            <Box args={[1, 0.3, 0.5]}>
                <meshStandardMaterial color = {0xb098c2} />
                <Box args={[0.8, 0.04, 0.02]} position={[0, 0.1,-0.3]}>
                    <meshPhysicalMaterial color={0x00b8ff} emissive={0x00b8ff} emissiveIntensity={10} toneMapped={false}/>
                </Box> 
            </Box>
        </animated.group> 

        <animated.group ref = {vrHand} scale = {vrHandScale} position-x = {vrHandX} position-y = {vrHandY} position-z = {vrHandZ}>
            <Sphere args = {[0.25, 32, 16]}>
                <meshStandardMaterial color = "orange" />
            </Sphere>
        </animated.group> 

        {/* <Robot whatsVisible = {props.whatsVisible} mat={1}></Robot> */}
        <Robot whatsVisible = {props.whatsVisible} mat={2} darkMode={props.darkMode}></Robot>
 

        {/* <Plane args = {[100,100]} position={[0,0,10]} rotation-x = {3.14}>
            <meshStandardMaterial color = "black" />
        </Plane> */}
    </group>


    </>
}