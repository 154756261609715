import { Text, Torus, Circle} from '@react-three/drei'
import { useState, useRef, useEffect} from 'react'
import { useFrame } from '@react-three/fiber';
import { MeshBasicMaterial } from 'three';


export default function Scoreboard(props)
{
    const scoreRef = useRef();
    const [playerScore, setPlayerScore] = useState();
    const [aiScore, setAIScore] = useState()
    const tennisScoring = ((score)=>
    {
        let converted = '-';

        if      (score === 0) converted = '0'
        else if (score === 1) converted = '15'
        else if (score === 2) converted = '30'
        else if (score === 3) converted = '40'
        else if (score === 4) converted = 'AD'
        else if (score  >= 5) converted = 'SET'
        return converted;
    })

    const hideScore = (()=> {
        setShowTheScore(false);
    })

    useEffect(()=>
    {
        let p = tennisScoring(props.pScore)
        let a = tennisScoring(props.aScore)

        if (p != 'SET' && a != 'SET')
        {
            if (p === 'AD' && a === 'AD')
            {
                p='40'
                a = '40'
                props.updateGameStateProperties({aiScore:3, playerScore:3})
            }
            else if (p === 'AD' && a != '40')
            {
                p = 'SET'
                props.updateGameStateProperties({aiScore:0, playerScore:0, playerSets: props.pSets+1})
                //props.setGameWinner('player')
            }
            else if (p != '40' && a === 'AD')
            {
                a = 'SET'
                props.updateGameStateProperties({aiScore:0, playerScore:0, aiSets: props.aSets+1})

                //props.setGameWinner('ai')
            }
        }
        else if (p === 'SET')
        {
            //props.setGameWinner('player')
            props.updateGameStateProperties({aiScore:0, playerScore:0, playerSets: props.pSets+1})
        }
        else if (a === 'SET')
        {
            //props.setGameWinner('ai')
            props.updateGameStateProperties({aiScore:0, playerScore:0, aiSets: props.aSets+1})

        }
        
        setPlayerScore(p)
        setAIScore(a)
        
    },[props.pScore, props.aScore])

    const showLevel = ()=>{
        let r = [];
        let i;
        for (i=0;i<Math.min(props.pSets, 6);i++)
        {
            r.push(<Circle key={i} args={[0.5,10]} position={[-3+i,-3,0]}>
                <meshBasicMaterial color={'orange'}/>
            </Circle>)
        }  
        for (;i<6;i++)
        {
            r.push(<Torus key={i} args={[0.35,0.07,10]} position={[-3+i,-3,0]}>
                    <meshBasicMaterial color={'orange'}/>
                </Torus>)
        } 
        return r; 
    }

    return <>
    {props.showScore? 
        <group ref = {scoreRef}>
           <group position={[-1,10,-25]}>
                <Text fontSize={0.8} color={0xffffff} position={[-6,1.5,0.8]} anchorX='left'>PLAYER</Text>
                <Text fontSize={1.2} color={'orange'} position={[-6.1,0.3,0.8]} anchorX='left'>YOU</Text>
                <Text fontSize={1.2} color={'orange'} position={[-6.1,-1,0.8]} anchorX='left'>ME</Text>

                <Text fontSize={0.7} color={0xffffff} position={[0.7,1.5,0.8]} anchorX='left'>SETS</Text>
                <Text fontSize={1.2} color={'orange'} position={[0.8,0.3,0.8]} anchorX='left'>{props.pSets<10?"0"+props.pSets:props.pSets}</Text>
                <Text fontSize={1.2} color={'orange'} position={[0.8,-1,0.8]} anchorX='left'>{props.aSets<10?"0"+props.aSets:props.aSets}</Text>

                <Text fontSize={0.7} color={0xffffff} position={[3.8,1.5,0.8]} anchorX='left'>SCORE</Text>
                <Text fontSize={1.2} color={'orange'} position={[4.3,0.3,0.8]} anchorX='left'>{playerScore!=0?playerScore:"0"+ playerScore}</Text>
                <Text fontSize={1.2} color={'orange'} position={[4.3,-1,0.8]} anchorX='left'>{aiScore!=0?aiScore:"0" + aiScore}</Text>
                {showLevel()}
            </group>
        </group> 
        : null
    }
    </>
}

