import * as THREE from 'three'
import { Cylinder, Sphere, useScroll, Torus } from '@react-three/drei'
import { useEffect, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { useSpring, useChain, useSpringRef, animated, easings} from '@react-spring/three'
import { MathUtils } from 'three'
import { config } from "./States/config";
import  Racket from './Racket'

export default function AI2(props)
{
    const head = useRef();
    const body = useRef(); 
    const racket = useRef();
    const hand = useRef()
    const [invisible, setVisible] = useState(true)
    const scrollData = useScroll();

    const [aiConfigs, setAIConfigs] = useState({
        side: 'ai',
        bodyOffset: new THREE.Vector3(0,0,0),
        bodyRotation: 0.15,
        headOffset: 7.5, 
        headHeight: 1.6,
        handOffset: new THREE.Vector3(0.7,0,0)
    })

    const [targetPosition, setTargetPosition] = useState({x:config.playerDistanceFromCenter, y:0, z:-config.playerDistanceFromNet})

    const headSpringRef = useSpringRef()
    const headPos = useSpring({x: props.aiState.aiPosition === 'left'? -6.5: 8.5, 
        ref: headSpringRef,
        config: 
        { 
            mass: 5, 
            tension: 600, 
            friction: 70, 
            precision: 0.001, 
            easing: easings.easeInOutBounce 
        },
    })
    
    const startAngle = Math.PI/6; // Starting angle in radians
    const racketSpringRef = useSpringRef()
    const [{racketX, racketY, racketZ, racketRotationX, racketRotationY, racketRotationZ}, setSwingSpring] = useSpring(() => ({
        ref: racketSpringRef,
        racketX: 1 * Math.cos(startAngle), 
        racketY: 0, 
        racketZ: 1 * Math.sin(startAngle),
        racketRotationX: 0.2,
        racketRotationY: -1,
        racketRotationZ: -0.7, 
        config: {     
            mass: 5, 
            tension: 900, 
            friction: 50, 
            precision: 0.05, 
            easing: easings.easeInOutElastic
         },
         onRest: ()=>{props.updateAIStateProperties({isSwinging: false})}
        
    }));

    useChain([headSpringRef, racketSpringRef]);

    useEffect (()=>
    {
        if (props.aiState.isSad)
            head.current.position.set(head.current.position.x -0.5, head.current.position.y - 0.3, head.current.position.z +1)
        setTimeout(() => {
            props.updateAIStateProperties({isSad: false})
            head.current.position.set(props.aiState.aiPosition === 'left'? -6.5: 8.5, aiConfigs.headHeight, 0)
        }, 1000);

    }, [props.aiState.isSad])

    useEffect (()=>
    {
        if (props.aiState.isHappy)
            head.current.position.set(head.current.position.x, aiConfigs.headHeight + 1, head.current.position.z)
        setTimeout(() => {
            props.updateAIStateProperties({isHappy: false})
            head.current.position.set(props.aiState.aiPosition === 'left'? -6.5: 8.5, aiConfigs.headHeight, 0)
        }, 3000);

    }, [props.aiState.isHappy])

    useEffect (()=>
    {
        setTargetPosition(props.aiState.aiPosition === 'left'? {x: -config.playerDistanceFromCenter+1, y:0, z: -config.playerDistanceFromNet} :{x: config.playerDistanceFromCenter+2, y:0, z: -config.playerDistanceFromNet})
        body.current.rotation.z = MathUtils.lerp(0, -2, 0.1)
    }, [props.aiState.aiPosition])

    useEffect (()=>
    {
        props.updateAIStateProperties({isHappy: false})

    }, [])

    useFrame ((state, delta) =>
    {
                   
        body.current.position.lerp(targetPosition, 10 * delta)

        if (body.current.position.distanceTo(targetPosition)<0.05)
        {                
            if (props.aiState.isMoving)           
            {
                props.updateAIStateProperties({isMoving: false})  
            }
        }
        if (props.aiState.isHumping)
        {
            body.current.position.y = props.aiState.isHappy? Math.abs(Math.sin(Math.PI * state.clock.elapsedTime)): (Math.sin(Math.PI * state.clock.elapsedTime)/6)
            hand.current.position.y = props.aiState.isHappy? 1.5 + Math.abs(Math.sin(Math.PI * state.clock.elapsedTime)): 1.5 + (Math.sin(Math.PI * state.clock.elapsedTime)/6)
        }
      
        if (props.aiState.isSwinging) 
        {
            setSwingSpring.start({ 
                //delay: 350,
                racketX: -0.5 * Math.cos(Math.PI),
                racketY: 1, 
                racketZ: 0.5 * Math.sin(Math.PI/2), 
                racketRotationX: -0.5,
                racketRotationY: 2.5,
                racketRotationZ: 1,
            });
        }
        else
        {
            setSwingSpring.start({ 
                racketX: -1 * Math.cos(startAngle), 
                racketY: 0, 
                racketZ: -1 * Math.sin(startAngle),
                racketRotationX: -0.2,
                racketRotationY: -1,
                racketRotationZ: 0.7, 
            });
        }    

        if (scrollData.range(0,1)*100>  13 )
            setVisible(false)
        else
            setVisible(true)
    })

    return <>
    
        {/* HAND */}
        <animated.group ref = {hand} visible={invisible} position-x = {headPos.x} position-y = {1.5} position-z = {-config.playerDistanceFromNet}>


                <animated.group ref = {racket} 
                position-x = {racketX}
                position-y = {racketY}
                position-z = {racketZ}
                rotation-x = {racketRotationX}
                rotation-y = {racketRotationY}
                rotation-z = {racketRotationZ}
                >
                    <Sphere args = {[0.25, 32, 16]}>
                        <meshToonMaterial color = "orange" />
                    </Sphere>
                    <Racket scale = {0.8}  
                        position-y={-0.4}
                    />

                </animated.group>    
                <meshToonMaterial color={"red"} />      
  
        </animated.group>

        {/* HEAD */}
        <animated.group ref = {head} castShadow visible={invisible} position-x = {headPos.x} position-y = {aiConfigs.headHeight}>
            <Sphere args = {[0.55, 32, 16]} position = {[0, aiConfigs.headHeight, -config.playerDistanceFromNet]} rotation-y={Math.PI}>
                <meshToonMaterial color = "orange" />
            </Sphere>
        </animated.group>

        {/* BODY */}
        <group ref = {body} visible={invisible} position = {[config.playerDistanceFromCenter-1, 0, -config.playerDistanceFromNet]} rotation-y={Math.PI}>
            <Sphere castShadow args = {[0.7, 32, 16]} position = {[0, 1.7, 0]}>
                <meshToonMaterial color = {0x004e8b} />
            </Sphere>
            <Cylinder castShadow args = {[0.7, 0.8, 1.4, 16]} position = {[0,1,0]} rotation-y={Math.PI}>
                <meshToonMaterial color = {0x004e8b} />
            </Cylinder>
            <Cylinder castShadow args = {[0.7, 0.7, 0.6, 16]} position = {[0,0,0]} rotation-y={Math.PI}>
                <meshToonMaterial color = "orange" />
            </Cylinder>
        </group>
    </>       
}