/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";


export default function Racket(props) {
  const { nodes, materials } = useGLTF("/Models/Racket/Racket.glb");
  return (
    <group {...props} dispose={null}>
      {/* <Sphere/> */}
      <mesh
        castShadow
        
        geometry={nodes.Circle.geometry}
        material={materials["Material.001"]}
        position={[0.025, 0.826, -0.062]}
        rotation={[1.576, -0.016, 0.008]}
      >
        <meshToonMaterial  color = "limegreen" shad={true}/>
      </mesh>
      <mesh
        castShadow
        
        geometry={nodes.Cube.geometry}
        material={materials["Material.003"]}
        position={[-0.006, -1.064, 0.018]}
        rotation={[1.576, -0.016, 0.008]}
        scale={0.097}
      >
        <meshToonMaterial  color = {0xae7ec4} shad={true}/>
      </mesh>
      <mesh
        castShadow
        
        geometry={nodes.Plane.geometry}
        material={materials.Material}
        position={[0.024, 0.825, 0.05]}
        rotation={[1.576, -0.016, 0.008]}
        scale={[0.937, 0.937, 1.133]}
      >
        <meshToonMaterial  color = "grey" shad={true}/>
      </mesh>
    </group>
  );
}

useGLTF.preload("/Models/Racket/Racket.glb");
