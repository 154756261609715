
import { Cylinder, Sphere, useScroll} from '@react-three/drei'
import { Suspense, useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { animated} from '@react-spring/three'
import Handwave from './Handwave'
import GamifyTheWorld from './GamifyTheWorld'
import { Kuma } from './Kuma'
import WorkStuff from './WorkStuff'
import ArtStuff from './ArtStuff'
import IntroText from './IntroText'

export default function Me(props)
{
    const head = useRef();
    const body = useRef();     

    const scrollData = useScroll();
    const [isVisible, setIsVisible] = useState(false)
    const [whatsVisible, setWhatsVisible] = useState(
        {
            meFrom: 13, meTo: 110,
            rackethandFrom: 12, rackethandTo: 18,
            handwaveFrom: 18, handwaveTo: 25,
            gamifytheworldFrom: 25, gamifytheworldTo: 35,
            workstuffFrom:38, workstuffTo: 57,
            hologramFrom: 45, hologramTo: 52,
            kumaFrom: 56, kumaTo: 73,
            bookFrom: 55, bookTo: 73,
            artstuffFrom: 70, artstuffTo:92
        }
    )

    useFrame ((state, delta) =>
    {
        //console.log(scrollData.range(0, 1) * 100)
        setIsVisible(scrollData.range(0,1) * 100>whatsVisible.meFrom && scrollData.range(0,1)*100<whatsVisible.meTo)
    })

    return <>
        
        <group visible = {isVisible} position-x = {3.5} position-y = {28} position-z = {38} rotation-x={0.2}>

            {/* HEAD */}
            <animated.group ref = {head} position-x = {0.2}>
                <Sphere args = {[0.55, 32, 16]} position = {[-0.7, 3.2, 5]} rotation-y={Math.PI}>
                    <meshToonMaterial  color = "orange" />
                </Sphere>
            </animated.group>

            {/* BODY */}
            <group ref = {body} position = {[0, 0, 5]} rotation = {[0, Math.PI, -0.2]}>
                <Sphere castShadow={true} args = {[0.7, 32, 16]} position = {[0, 1.7, 0]}>
                    <meshToonMaterial  color = {0x004e8b}/>
                </Sphere>

                <Cylinder castShadow={true} args = {[0.7, 0.8, 1.4, 16]} position = {[0,1,0]} rotation-y={Math.PI}>
                    <meshToonMaterial  color = {0x004e8b}/>
                </Cylinder>

                <Cylinder castShadow={true} args = {[0.7, 0.7, 0.6, 16]} position = {[0,0,0]} rotation-y={Math.PI}>
                    <meshToonMaterial  color = "orange" />
                </Cylinder>
                
                <Suspense><Handwave whatsVisible = {whatsVisible}/></Suspense>
                <Suspense><GamifyTheWorld whatsVisible = {whatsVisible} /></Suspense>
                <Suspense><WorkStuff whatsVisible = {whatsVisible} darkMode={props.darkMode}/></Suspense>
                <Suspense><Kuma scale={0.2} whatsVisible = {whatsVisible}></Kuma></Suspense>
                <Suspense><ArtStuff scale = {4} whatsVisible = {whatsVisible}></ArtStuff></Suspense>
                
            </group>
        </group>
        <IntroText darkMode = {props.darkMode}/> 
    </>       
}