import { useSpring, animated} from '@react-spring/three'
import { Sphere } from "@react-three/drei";
import { useState, useRef, useEffect } from "react"


export default function BouncingBall(props)
{
    const bBallRef = useRef();

    //Animation Springs
    const [ballBouncing, setBallBouncing] = useState(false);
    const [ballThrowing, setBallThrowing] = useState(false);

    // Define the starting and ending points for the ball's bounce
    const bounceStartPoint = [7,0,-16]
    const bounceEndPoint = [7,3,-16];
    
    // Set up the spring animation for position and scale
    const { position: bouncePosition, scale: bounceScale } = useSpring({
        position: ballBouncing ? bounceStartPoint :bounceEndPoint,
        scale:    ballBouncing ? [1, 0.5, 1] : [1, 1.3, 1],
        config: {
        tension: 200, 
        friction: 20,
        },
    });

    const throwStartPoint = [7,3,-16]
    const throwEndPoint = [7,6,-16];

    const { position: throwPosition, scale: throwScale } = useSpring({
        position: ballThrowing ? throwEndPoint:throwStartPoint,
        scale:    ballThrowing ? [1, 0.6, 1] : [1, 1.1, 1],
        config: {
        tension: 100, 
        friction: 40,
        precision: 0.1
        },
        onRest: ()=>{if (props.bouncingBall === 1) props.setBouncingBall(2)}
    });
    

    // Function to toggle the ballBouncing state
    const toggleBouncing = () => {
        setBallBouncing((prevBouncing) => !prevBouncing);
    };

    const prevCountRef = useRef(props.bouncingBall);

    // Use useEffect to toggle the ballBouncing state every two seconds
    useEffect(() => { 
        if (props.bouncingBall === 0)
        {   
            setBallBouncing(true);
            setBallThrowing(false)
            const interval = setInterval(toggleBouncing, 400);
            return () => clearInterval(interval);
        }  
        if (props.bouncingBall === 1)
        {
           // if (prevCountRef.current === 0)
            {
                setBallBouncing(false)
                setBallThrowing(true)
            }
        }
        else if (props.bouncingBall === 2 || props.bouncingBall === 3)
        {
            setBallBouncing(false)
            setBallThrowing(false)
        }
        prevCountRef.current = props.bouncingBall;

    }, [props.bouncingBall]);


    return <>
        <group ref = {bBallRef} visible = {props.bouncingBall === 3? false:true}>
            <animated.group position = {
                props.bouncingBall=== 0? 
                bouncePosition: throwPosition} 
              
                scale = {
                props.bouncingBall === 0? 
                bounceScale: throwScale}>

                <Sphere castShadow args = {[0.4, 32, 16]}>
                    <meshToonMaterial color= {props.ballColor} />
                </Sphere>

            </animated.group>
        </group>
    </>
}