import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas,  } from '@react-three/fiber'
import Experience from './Experience.jsx'
import ContactForm from './ContactForm'
import { AdaptiveDpr, useProgress, Html} from '@react-three/drei'
import { useState, useEffect, useRef } from "react";
import { Suspense, } from 'react'
import loaderGif from '/Textures/dance.gif'; // adjust the path accordingly

const root = ReactDOM.createRoot(document.querySelector('#root'))

function Loader() {
    //const { active, progress, errors, item, loaded, total } = useProgress()
    return <Html center><div className="loader-container"> <span className="loader-class"><img src={loaderGif} alt="Loading..." style={{ mixBlendMode: 'multiply' }}/>
    </span> </div></Html>
        // className="loader-class">Loading</Html>
  }
  
root.render(
    <>
    <Canvas
        performance={{ min: 0.5 }}
        dpr={[1, 2]}
        shadows
        gl={{ antialias: true }}
        camera={ {
            fov: 30,
            near: 5,
            far: 300,
            position: [ 0, 30, 65 ]
        } }
    >
        <Suspense fallback={<Loader/>}>    
        <AdaptiveDpr pixelated />
        <Experience/>
        </Suspense>
        
    </Canvas>
   
    <div>
        <ContactForm/>
    </div>
    </>

)