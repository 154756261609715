
import { useEffect, useRef } from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import { useSpring, useSpringRef, animated, easings} from '@react-spring/three'
import { useGLTF, useScroll, Box,Cylinder, Sphere} from "@react-three/drei";
import { DoubleSide } from 'three';
import * as THREE from 'three'

export default function ArtStuff(props) {
    const { nodes, materials } = useGLTF("./Models/Easel/easel.glb");
    const easel = useRef();
    const painting = useRef();
    const beret = useRef();
    const paintingHand = useRef()
    const scrollData = useScroll();
    const paintingTexture = useLoader(THREE.TextureLoader, './Textures/Painting.png');


    const rightHandSpringRef = useSpringRef()
    const [{rightHandX, rightHandY, rightHandZ, rightHandRotX, rightHandRotY, rightHandRotZ}, setWaveSpring] = useSpring(() => ({
        ref: rightHandSpringRef,
        loop: {reverse: true},
        from: {
            rightHandX: -1.4, 
            rightHandY: 3, 
            rightHandZ: 0,
            rightHandRotX: 1,
            rightHandRotY: -0.8,
            rightHandRotZ: -1.2
            },
        to: {
            rightHandX: -1, 
            rightHandY: 3.8, 
            rightHandZ: 0,
            rightHandRotX: 0.5,
            rightHandRotY: -1.2,
            rightHandRotZ: -0.7
        },
        config: {     
            mass: 5, 
            tension: 200, 
            friction: 50, 
            precision: 0.01, 
            easing: easings.easeInOutElastic
        },          
    }));

    const easelSpringRef = useSpringRef()
    const [{easelPosX, easelPosY, easelPosZ, paintingPosX, paintingPosY, paintingPosZ, beretPosY, beretScale}, setEaselSpring] = useSpring(() => ({
        ref: easelSpringRef,
        config: {     
            mass: 5, 
            tension: 600, 
            friction: 40, 
            precision: 0.01, 
            easing: easings.easeInOutBounce
         },
    }));

    useEffect(()=>{
        rightHandSpringRef.start()
    },[])
    useFrame(()=>{
        beret.current.visible = scrollData.range(0,1) * 100 > props.whatsVisible.artstuffFrom && scrollData.range(0,1) * 100 < props.whatsVisible.artstuffTo 
        paintingHand.current.visible = scrollData.range(0,1) * 100 > props.whatsVisible.artstuffFrom + 8 && scrollData.range(0,1) * 100 < props.whatsVisible.artstuffTo -4

        setEaselSpring.start({ 
            easelPosX: (scrollData.range(0,1) * 100) < props.whatsVisible.artstuffFrom + 5? -4: (scrollData.range(0,1) * 100)< props.whatsVisible.artstuffTo - 3? 0.1:-5,
            easelPosY: (scrollData.range(0,1) * 100) < props.whatsVisible.artstuffFrom + 5? 30:  (scrollData.range(0,1) * 100)< props.whatsVisible.artstuffTo - 3? -1.3:30, 
            easelPosZ: (scrollData.range(0,1) * 100) < props.whatsVisible.artstuffFrom + 5? 0:   (scrollData.range(0,1) * 100)< props.whatsVisible.artstuffTo - 3? 1:0, 
            paintingPosX: (scrollData.range(0,1) * 100) < props.whatsVisible.artstuffFrom + 6? -5: (scrollData.range(0,1) * 100)< props.whatsVisible.artstuffTo - 4? -0.4:-5,
            paintingPosY: (scrollData.range(0,1) * 100) < props.whatsVisible.artstuffFrom + 6? 50:  (scrollData.range(0,1) * 100)< props.whatsVisible.artstuffTo - 4? 3.3:50, 
            paintingPosZ: (scrollData.range(0,1) * 100) < props.whatsVisible.artstuffFrom + 6? 0:   (scrollData.range(0,1) * 100)< props.whatsVisible.artstuffTo - 4? 1.7:0, 
            beretPosY: (scrollData.range(0,1) * 100) < props.whatsVisible.artstuffFrom + 5? 4: (scrollData.range(0,1) * 100)< props.whatsVisible.artstuffTo - 2? 3.25: 4,
            beretScale: (scrollData.range(0,1) * 100) < props.whatsVisible.artstuffFrom + 5? 0: (scrollData.range(0,1) * 100)< props.whatsVisible.artstuffTo - 2? 0.4: 0
        }); 
    })
  return (
    <>
        <animated.group ref= {easel} {...props} dispose={null} position-x={easelPosX} position-y={easelPosY} position-z={easelPosZ}>
        <mesh
            castShadow
            receiveShadow
            geometry={nodes.Object_9.geometry}
            material={materials.Easel1}
            //position={[-0.04, -0.8, 1]}
            rotation={[-Math.PI/2 + 0.4, -0.16, Math.PI - 0.2]}
        >
            <meshToonMaterial color={0xa9805f}/>
        </mesh>
        
        </animated.group>

        <animated.group position-x={paintingPosX} position-y={paintingPosY} position-z={paintingPosZ}>
            <Box ref={painting} args={[3.2, 4, 0.05]} rotation={[0.5, 0, 0.15]}>
                <meshStandardMaterial  map = {paintingTexture} />
            </Box> 
        </animated.group>

        <animated.group ref={paintingHand} scale={1} position-x={rightHandX} position-y={rightHandY} position-z={rightHandZ} 
            rotation-x={rightHandRotX} rotation-y={rightHandRotY} rotation-z={rightHandRotZ}>
            <Sphere args = {[0.25, 32, 16]}>
                <meshStandardMaterial color = "orange" />
            </Sphere>
            <Cylinder args={[0.06, 0.04, 0.9]} position={[0,-0.1,0]}> 
                <meshStandardMaterial color = "black"/>
            </Cylinder>
        </animated.group> 

        <animated.group ref={beret} scale = {beretScale} position-x={-0.02} position-y={beretPosY} position-z={-0.01} rotation={[0.3,0.1,0.3]}>
            <Sphere args = {[2, 12, 12,0,6.82,5.2,1.3]} rotation ={[0,0,0.2]}>
                <meshStandardMaterial color = {0x6ba864} side={DoubleSide}/>
            </Sphere>
            <Cylinder args={[1.3, 1.2, 0.5]} position={[0,1,0]}> 
                <meshStandardMaterial color = {0x6ba864}/>
            </Cylinder>
            <Cylinder args={[0.1, 0.1, 0.5]} position={[-0.4,2,0]} rotation={[0,0,0.8]}> 
                <meshStandardMaterial color = {0x6ba864}/>
            </Cylinder>
        </animated.group> 
    </>
  );
}

useGLTF.preload("./Models/Easel/easel.glb");
