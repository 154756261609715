import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Chair(props) {
  const { nodes, materials } = useGLTF("/Models/Chair/Chair.glb");
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Refree_Chair.geometry}
        material={materials["LowPoly Tennis Pack Texture"]}
        scale={0.03}
        position = {[-18,-0.2,0]}
        rotation = {[0,1.1,0]}
      />
  </group>
  );
}

useGLTF.preload("/Models/Chair/Chair.glb");