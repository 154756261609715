import { Sphere, useScroll, useTexture } from '@react-three/drei'
import { useRef, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import { useSpring, useChain, useSpringRef, animated, easings} from '@react-spring/three'
import  Racket from './Racket'

export default function Handwave(props)
{
    const leftHand = useRef();
    const rightHand = useRef();
    const scrollData = useScroll();

    const leftHandSpringRef = useSpringRef()
    const [{leftHandX, leftHandY, leftHandZ, leftHandRotX, leftHandRotY, leftHandRotZ}, setLeftHandSpring] = useSpring(() => ({
        ref: leftHandSpringRef,
        config: {     
            mass: 5, 
            tension: 600, 
            friction: 60, 
            precision: 0.1, 
            easing: easings.easeInOutElastic
         },
         onRest:()=>{rightHandSpringRef.resume()}
    }));

    const rightHandSpringRef = useSpringRef()
    const [{rightHandX, rightHandY, rightHandZ, rightHandRotX, rightHandRotY, rightHandRotZ}, setWaveSpring] = useSpring(() => ({
        ref: rightHandSpringRef,
        loop: {reverse: true},
        from: {
            rightHandX: 1.5, 
            rightHandY: 2.2, 
            rightHandZ: 0,
            rightHandRotX: 0.2,
            rightHandRotY: -1,
            rightHandRotZ: -0.7},
        to: {
            rightHandX: 0.8, 
            rightHandY: 2.6, 
            rightHandZ: 0,
            rightHandRotX: 0.2,
            rightHandRotY: -1,
            rightHandRotZ: -0.7
        },
        config: {     
            mass: 5, 
            tension: 200, 
            friction: 10, 
            precision: 0.1, 
            easing: easings.easeInOutElastic
         },          
    }));
    useChain([leftHandSpringRef, rightHandSpringRef])

    useFrame ((state, delta) =>
    {
        leftHand.current.visible = scrollData.range(0,1) * 100 > props.whatsVisible.rackethandFrom && scrollData.range(0,1) * 100 < props.whatsVisible.rackethandTo
        rightHand.current.visible = scrollData.range(0,1) * 100 > props.whatsVisible.handwaveFrom && scrollData.range(0,1) * 100 < props.whatsVisible.handwaveTo

        setLeftHandSpring.start({ 
            leftHandX:    (scrollData.range(0,1) * 100) < props.whatsVisible.rackethandFrom + 3? 1: (scrollData.range(0,1) * 100)< props.whatsVisible.rackethandTo - 2? 0.7 * Math.cos(Math.PI) : -0.8,
            leftHandY:    (scrollData.range(0,1) * 100) < props.whatsVisible.rackethandFrom + 3? 2:  (scrollData.range(0,1) * 100)< props.whatsVisible.rackethandTo - 2? 1: -0.5, 
            leftHandZ:    (scrollData.range(0,1) * 100) < props.whatsVisible.rackethandFrom + 3? -1:    (scrollData.range(0,1) * 100)< props.whatsVisible.rackethandTo - 2? -1 * Math.sin(Math.PI/2): 3, 
            leftHandRotX: (scrollData.range(0,1) * 100) < props.whatsVisible.rackethandFrom + 3? 0.5:  (scrollData.range(0,1) * 100)< props.whatsVisible.rackethandTo - 2? -0.2: -0.4,
            leftHandRotY: (scrollData.range(0,1) * 100) < props.whatsVisible.rackethandFrom + 3? -0.9:   (scrollData.range(0,1) * 100)< props.whatsVisible.rackethandTo - 2? 3: 5,
            leftHandRotZ: (scrollData.range(0,1) * 100) < props.whatsVisible.rackethandFrom + 3? -0.3:  (scrollData.range(0,1) * 100)< props.whatsVisible.rackethandTo - 2? -2.2: -2.3,
        });
    })

    return<>
        <group position-x = {0} position-y = {0} position-z = {0}>
        {/* Left HAND */}
            <animated.group ref = {leftHand} position-x = {leftHandX} position-y = {leftHandY} position-z = {leftHandZ} rotation-x = {leftHandRotX} rotation-y={leftHandRotY} rotation-z={leftHandRotZ} >
                <group>
                    <Sphere args = {[0.25, 32, 16]}>
                        <meshStandardMaterial color = {"orange"} />
                    </Sphere>   
                    <Racket scale = {0.7} position-y = {-0.4}/> 
                </group>
            </animated.group>

         {/* RIGHT HAND */}
            <animated.group ref = {rightHand} position-x = {rightHandX} position-y = {rightHandY} position-z = {rightHandZ} 
            rotation-x = {rightHandRotX} rotation-y = {rightHandRotY} rotation-z = {rightHandRotZ}
                >
                <Sphere args = {[0.25, 32, 16]}>
                    <meshStandardMaterial color = "orange" />
                </Sphere>
            </animated.group> 
        </group>
    </>
}

