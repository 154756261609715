import { useScroll, Scroll,  Plane} from '@react-three/drei'
import { useState, useRef, useEffect } from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import * as THREE from 'three'

export default function IntroText(props)
{
   
    const [visible, setVisible] = useState(false)
    const [logs, setLogs] = useState(0)
    const scrollData = useScroll();
    const scrollPoint = useState([18,19,21,29,32,42,47,58,60,62,65,69,83,97])
    const [opacity, setOpacity] = useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0])
    const text0 = useRef();
    const text1 = useRef();
    const text2 = useRef();
    const text3 = useRef();
    const text4 = useRef();
    const text5 = useRef();
    const text6 = useRef();
    const text7 = useRef();
    const text8 = useRef();
    const text9 = useRef();
    const text10 = useRef();
    const text11 = useRef();
    const text12 = useRef();
    const text13 = useRef();
    const text0atex = useLoader(THREE.TextureLoader, './Textures/TextTextures/text0-1.png');
    const text0btex = useLoader(THREE.TextureLoader, './Textures/TextTextures/text0-2.png');
    const text1tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text1.png');
    const text2tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text2.png');
    const text3tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text3.png');
    const text4tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text4.png');
    const text5tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text5.png');
    const text6tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text6.png');
    const text7tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text7.png');
    const text8tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text8.png');
    const text9tex  = useLoader(THREE.TextureLoader, './Textures/TextTextures/text9.png');
    const text10tex = useLoader(THREE.TextureLoader, './Textures/TextTextures/text10.png');
    const text11tex = useLoader(THREE.TextureLoader, './Textures/TextTextures/text11.png');
    const text12tex = useLoader(THREE.TextureLoader, './Textures/TextTextures/text12.png');
    const text13tex = useLoader(THREE.TextureLoader, './Textures/TextTextures/text13.png');
    
    
    
const updateIndex = (index, newValue) => {
    // Create a new array with the updated value at the specific index
    const updatedArray = opacity.map((value, i) => {
        if (i === index) {
        return newValue;
        }
        return value;
    });

    // Set the state with the new array
    setOpacity(updatedArray)
    };

const computeOpacity = (index) => {
    const offset = 4;

    if (scrollData.range(0,1) * 100 >= scrollPoint[0][index]-offset && scrollData.range(0,1) * 100 <= scrollPoint[0][index]+offset)
    {
        if (opacity[index]<1)
        updateIndex (index, opacity[index]+0.07)
    }
    else
    {
        if (opacity[index]>0)
        updateIndex (index, opacity[index]-0.07)
    }
    return opacity[index]
};    

useEffect(() => {
    if (logs === 1)
       console.log("Dude, you're such a nerd! Why are you inspecting the console when the action is all on the screen?")
    if (logs === 2)
       console.log("Ah, back for more, huh? Still not convinced there's nothing here?")
    if (logs === 3)
       console.log("Omg, you're still here? Well, spoiler alert: the cake is a lie!")
    if (logs === 4)
       console.log("Okay, you're a curious one, aren't you? Don't you have a website to look at?")
    if (logs === 5)
       console.log("You're really digging deep, huh? Looking for cheat codes? This isn't a 90s video game!")
    if (logs === 6)
       console.log("Hey, while you're here, can you check if I left any debug logs? K, thanks.")
    if (logs === 7)
       console.log("Do you always go snooping around people's consoles on the first visit? No wonder you're single.")
    if (logs === 8)
       console.log("Is this your idea of hacking? Dude, Mr. Robot you ain't.")
    if (logs === 9)
       console.log("Aha! Caught you red-handed! Now, go look at my website or else I'll unleash infinite alert boxes!")
    if (logs === 10)
       console.log("Seriously? Still here? You must be really fun at parties. Looking for party codes, are you?")
    if (logs === 11)
       console.log("You've reached the end of the console! Claim your prize of... absolutely nothing! Now go back and enjoy the actual game!")
  }, [logs]);

useFrame ((state, delta) =>
{
    if (state.clock.elapsedTime>10)
        setLogs(1);
    if (state.clock.elapsedTime>20)
        setLogs(2);
    if (state.clock.elapsedTime>35)
        setLogs(3);
    if (state.clock.elapsedTime>50)
        setLogs(4);
    if (state.clock.elapsedTime>70)
        setLogs(5);
    if (state.clock.elapsedTime>90)
        setLogs(6);
    if (state.clock.elapsedTime>120)
        setLogs(7);
    if (state.clock.elapsedTime>180)
        setLogs(8);
    if (state.clock.elapsedTime>220)
        setLogs(9);
    if (state.clock.elapsedTime>250)
        setLogs(10);      
    if (state.clock.elapsedTime>300)
        setLogs(11);    
    



    setVisible(scrollData.range(0,1)>0.02)
    text0.current.opacity = computeOpacity(0)
    text1.current.opacity = computeOpacity(1)
    text2.current.opacity = computeOpacity(2)
    text3.current.opacity = computeOpacity(3)
    text4.current.opacity = computeOpacity(4)
    text5.current.opacity = computeOpacity(5)
    text6.current.opacity = computeOpacity(6)
    text7.current.opacity = computeOpacity(7)
    text8.current.opacity = computeOpacity(8)
    text9.current.opacity = computeOpacity(9)
    text10.current.opacity = computeOpacity(10)
    text11.current.opacity = computeOpacity(11)
    text12.current.opacity = computeOpacity(12)
    text13.current.opacity = computeOpacity(13)
    
    if (scrollData.range(0,1)>0.01)
     {
        const element = document.getElementById("mouse");
        element.classList.remove("unfade");
        element.classList.add("fade");
     }
     else{
        const element = document.getElementById("mouse");
        element.classList.remove("fade");
        element.classList.add("unfade");
     }

})
return<>

<group>
        <Scroll >     

            <Plane args={[50,40]} scale={0.16} position={[-1.4,-5,38.1]}>
                <meshBasicMaterial ref={text0} map={text0btex} transparent color={props.darkMode? 0xffffff:0x386b42}/>
            </Plane>

            <Plane  args={[50,25]} scale={0.15} position={[-1.5,-9,38.1]}>
                <meshBasicMaterial ref={text1} map={text1tex} transparent color={props.darkMode? 0xffffff:0x386b42}/>
            </Plane>
           
            <Plane  args={[60,33]} scale={0.16} position={[-0.5,-14,38.1]}>
                <meshBasicMaterial ref={text2} map={text2tex} transparent color={props.darkMode? 0xffffff:0x386b42}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-1.5,-28,35.1]}>
                <meshBasicMaterial ref={text3}  map={text3tex} transparent color={props.darkMode? 0x1a1a1a:0xd2385a}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-1.5,-34,35.1]}>
                <meshBasicMaterial ref={text4} map={text4tex} transparent color={props.darkMode? 0x1a1a1a:0xd2385a}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-2.4,-54,35.1]}>
                <meshBasicMaterial ref={text5} map={text5tex} transparent color={props.darkMode? 0xffffff:0xe2e2e2}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-2.4,-62,35.1]}>
                <meshBasicMaterial ref={text6} map={text6tex} transparent color={props.darkMode? 0xffffff:0xe2e2e2}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-1.8,-84,35.1]}>
                <meshBasicMaterial ref={text7} map={text7tex} transparent color={props.darkMode? 0x1a1a1a:0xfbecc9}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-1.8,-88,35.1]}>
                <meshBasicMaterial ref={text8} map={text8tex} transparent color={props.darkMode? 0x1a1a1a:0xfbecc9}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-1.8,-92,35.1]}>
                <meshBasicMaterial ref={text9} map={text9tex} transparent color={props.darkMode? 0x1a1a1a:0xfbecc9}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-1.8,-97,35.1]}>
                <meshBasicMaterial ref={text10} map={text10tex} transparent color={props.darkMode? 0x1a1a1a:0xfbecc9}/>
            </Plane>

            <Plane args={[50,40]} scale={0.16} position={[-1.8,-104,35.1]}>
                <meshBasicMaterial ref={text11} map={text11tex} transparent color={props.darkMode? 0x1a1a1a:0xfbecc9}/>
            </Plane>

            <Plane args={[50,40]} scale={0.17} position={[-2.5,-130,35.1]}>
                <meshBasicMaterial ref={text12} map={text12tex} transparent color={props.darkMode? 0xffffff:0xf9ff4b}/>
            </Plane>

            <Plane args={[50,40]} scale={0.18} position={[-1.3,-152,35.1]}>
                <meshBasicMaterial ref={text13} map={text13tex} transparent color={props.darkMode? 0x1a1a1a:0x195787}/>
            </Plane>
        </Scroll>
    </group>
</>
}