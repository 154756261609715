import { Scroll, ScrollControls} from '@react-three/drei'
import { useState, useEffect, useRef, useContext} from 'react'
import { useFrame, useThree } from '@react-three/fiber';
import { Physics } from '@react-three/rapier'
import { MathUtils } from 'three';
import { Perf } from 'r3f-perf'
import * as THREE from 'three'
import Environment from './Environment'
import Me from './Me'
import Level2 from './Level2'


export default function Experience() {
  const [buttonsMargin, setbuttonsMargin] = useState(40) 
  const [cameraShake, setCameraShake] = useState(0)
  const { camera } = useThree(); // Accessing the camera
  const [darkMode, setDM] = useState(false);
  
 
  useEffect(() => {
    // Adjusting the FOV based on screen size
    const listener = new THREE.AudioListener();
    camera.add( listener );
    const handleResize = () => {
      if (window.innerWidth < 780) {
        camera.fov = 50; // FOV for mobile devices
        setbuttonsMargin(20) 
      } else {
        camera.fov = 35; // FOV for desktop
        setbuttonsMargin(40) 
      }
      camera.updateProjectionMatrix(); // Update the camera with new settings
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Calling once initially to set the correct FOV
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [camera]);

  useEffect(() => {
    if (cameraShake<0.1)
    {
      camera.position.x = 0;
      camera.position.z = 65;
    }
  }, [cameraShake]);

  useFrame((state, delta)=>{
    if (cameraShake>0.1)
    {
      camera.position.x += Math.sin(Math.PI * 20 * state.clock.elapsedTime)/20
      camera.position.z += Math.sin(Math.PI * 20 * state.clock.elapsedTime)/20
      setCameraShake(MathUtils.lerp(cameraShake, 0, 20 * delta))
    }
  }) 


  return (
    <>

      {/* <Perf position="top-left" /> */}
      {/* <OrbitControls makeDefault/> */}
      <ScrollControls pages={6} damping={0.2} distance={2}>

        <Me darkMode={darkMode}/>
        <Scroll>

          <Physics timeStep={1 / 120}>
            <Level2 buttonsMargin = {buttonsMargin} setCameraShake = {setCameraShake}/>
            <Environment setDM = {setDM}/>
          </Physics>   
        </Scroll>   
      </ScrollControls>
      
    </>
  );
}
