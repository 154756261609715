
import React, { Suspense, useEffect, useRef } from "react";
import { useGLTF, useAnimations, useScroll, useTexture, Circle, Sphere, Torus } from "@react-three/drei";
import { useSpring, useChain, useSpringRef, animated, easings} from '@react-spring/three'
import { useState } from "react";
import * as THREE from 'three'
import { useFrame } from "@react-three/fiber";

export function Kuma(props) {
  const group = useRef();
  const bookRef = useRef();
  const glassesRef = useRef();
  const scrollData = useScroll();
  const [thesisButton, setThesisButton] = useState(false);
  const [thesisButtonRotation, setThesisButtonRotation] = useState(false);
  const { nodes, animations } = useGLTF("/Models/Kuma/KUMA.glb");
  const { actions, mixer, clips } = useAnimations(animations, group);
  const metallicTex = useTexture("./Models/Kuma/Checker_metallic.jpg")
  const thesisGlow = useTexture('./Textures/thesisGlow.png');

  const [playAnimation, setPlayAnimation] = useState(false)
  const anim = useAnimations(animations);
  const mat = new THREE.MeshPhysicalMaterial(
    {
        color: 0xbcd3da,
        metalnessMap: metallicTex,
        toneMapped: false,
        metalness: 3,
    })

    const outline = new THREE.MeshPhysicalMaterial(
    {
        color: 'black',
        metalness: 3,
    })

    const bookSpringRef = useSpringRef()
    const [{bookPosX, bookPosY, bookPosZ, bookRotX, bookRotY, bookRotZ, bookScale, kumaScale}, setBookSpring] = useSpring(() => ({
        ref: bookSpringRef,
        config: {     
            mass: 5, 
            tension: 600, 
            friction: 100, 
            precision: 0.1, 
            easing: easings.easeOutBounce
         },
         //onResolve: ()=>{props.setCameraShake(1)}
    }));

    useEffect(() => {
        mixer.clipAction(animations[0])
        mixer._actions[0].play()
      }, []);

    useFrame((state, delta)=>{
        glassesRef.current.visible = scrollData.range(0,1) * 100 > props.whatsVisible.kumaFrom && scrollData.range(0,1) * 100 < props.whatsVisible.kumaTo
        group.current.visible = scrollData.range(0,1) * 100 > props.whatsVisible.kumaFrom && scrollData.range(0,1) * 100 < props.whatsVisible.kumaTo
        setThesisButton((scrollData.range(0,1) * 100 > props.whatsVisible.bookFrom+9 && scrollData.range(0,1) * 100 < props.whatsVisible.kumaTo-3))
        bookRef.current.visible = scrollData.range(0,1) * 100 > props.whatsVisible.bookFrom && scrollData.range(0,1) * 100 < props.whatsVisible.bookTo
        setBookSpring.start({ 
            bookPosX: (scrollData.range(0,1) * 100) < props.whatsVisible.bookFrom+9? -0.5: (scrollData.range(0,1) * 100)< props.whatsVisible.bookTo - 4? 1:-25,
            bookPosY: (scrollData.range(0,1) * 100) < props.whatsVisible.bookFrom+9? 1.6:  (scrollData.range(0,1) * 100)< props.whatsVisible.bookTo - 4? 2.7:-1, 
            bookPosZ: (scrollData.range(0,1) * 100) < props.whatsVisible.bookFrom+9? -1:   (scrollData.range(0,1) * 100)< props.whatsVisible.bookTo - 4? -1.8:10, 
            bookRotX: (scrollData.range(0,1) * 100) < props.whatsVisible.bookFrom+9? -2.2:   (scrollData.range(0,1) * 100)< props.whatsVisible.bookTo - 4? -2.75:-1.7,
            bookRotY: (scrollData.range(0,1) * 100) < props.whatsVisible.bookFrom+9? -2.5: (scrollData.range(0,1) * 100)< props.whatsVisible.bookTo - 4? -3:-10,
            bookRotZ: (scrollData.range(0,1) * 100) < props.whatsVisible.bookFrom+9? -1.3: (scrollData.range(0,1) * 100)< props.whatsVisible.bookTo - 4? -3:-2,
            bookScale:(scrollData.range(0,1) * 100) < props.whatsVisible.bookFrom+9? 0.4:  (scrollData.range(0,1) * 100)< props.whatsVisible.bookTo - 4? 0.9:0,
            kumaScale: scrollData.range(0,1) * 100  < props.whatsVisible.kumaFrom+2? 0:    (scrollData.range(0,1) * 100)< props.whatsVisible.kumaTo - 2? 0.2: 0
        }); 
       if (thesisButton)
          setThesisButtonRotation(thesisButtonRotation + 0.2 * delta)
        
    })
  return (
    <>
    <group ref={glassesRef} scale={0.2} position ={[-0.13,3.75,-1.7]} rotation={[0.4, -0.2, 0.15]}>
        <Torus args = {[1.1, 0.2, 5, 4]} position={[0,0,-0.5]} rotation-z = {0.8}>
            <meshStandardMaterial color = "black" />
        </Torus>
        <Torus args = {[1, 0.2, 5, 4]} position={[2.3,0,-0.5]} rotation-z = {0.8} >
            <meshStandardMaterial color = "black" />
        </Torus>
    </group>

    <Circle visible={thesisButton} args={[5,20]} position={[1,3,-2]} rotation={[0,Math.PI-0.4, thesisButtonRotation]}     
        onPointerOver={(e) => {thesisButton>0? document.body.style.cursor = 'pointer':null;}}
        onPointerOut={(e) => {thesisButton>0? document.body.style.cursor = 'auto':null;}}
        onClick={() => thesisButton>0? window.open("https://uhra.herts.ac.uk/handle/2299/17193"):null}>
          <meshBasicMaterial transparent map={thesisGlow} opacity={0.15} color={'white'}/>
    </Circle>

    <animated.group ref={bookRef} scale={bookScale} position-x={bookPosX} position-y={bookPosY} position-z={bookPosZ} rotation-x={bookRotX} rotation-y={bookRotY} rotation-z={bookRotZ}>
        <Book scale = {0.1}/>
        <Sphere args = {[0.35, 32, 16]} position={[-1.6, -0.1,-1.0]}>
            <meshStandardMaterial color = "orange" />
        </Sphere>   
        <Sphere args = {[0.35, 32, 16]} position={[1.6,-0.2, 2]}>
            <meshStandardMaterial color = "orange" />
        </Sphere>  
    </animated.group>

 
    <animated.group ref={group} {...props} dispose={null} position= {[-4, 4, 7]} rotation={[0.2,-3,-0.2]} scale={kumaScale}>

      <group name="Scene">
          <group name="Armature" position={[-8.667, 17.144, 0.082]}>
          <skinnedMesh
            name="ArmLong000"
            geometry={nodes.ArmLong000.geometry}
            material={outline}
            skeleton={nodes.ArmLong000.skeleton}
            
          />
          <skinnedMesh
            name="ArmLong037"
            geometry={nodes.ArmLong037.geometry}
            material={mat}
            skeleton={nodes.ArmLong037.skeleton}
          />
          <primitive object={nodes.Base} />
        </group>
      </group>
    </animated.group>
    </>
  );
}

useGLTF.preload("./Models/Kuma/KUMA.glb");

export function Book(props) {
    const { nodes, materials } = useGLTF("./Models/Kuma/book.glb");
    return (
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["Box001_01_-_Default_0"].geometry}
          material={materials["01_-_Default"]}
          position={[0, 0.354, 0]}
          scale={[0.631, 0.684, 0.686]}
        />
      </group>
    );
  }
  
  useGLTF.preload("./Models/Kuma/book.glb");